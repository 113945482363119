import React from "react";
import PortableText from "./portableText";
import Form from "../components/contact-form";

const FormSection = (props) => {
  const {
    formDescription
  } = props;

  return (
    <div>
      <div className="grid">
        <div className="col-12 col-md-6">
          <div className="content-sm">
            {formDescription.heading && (<h2 className="h1">{formDescription.heading}</h2>)}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="content-lg">
            {formDescription._rawContent && (<PortableText blocks={formDescription._rawContent} />)}
          </div>
          <Form />
        </div>
      </div>
    </div>
  );
}

export default FormSection;
