import React from "react";
import Container from "./container";

import Contacts from "../components/contact-contacts";
import FormSection from "../components/contact-form-section";

import * as styles from "../styles/components/contact-main.module.css";

const ContactMain = (props) => {

  const {
    title,
    contacts,
    formDescription
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {(title || (contacts && contacts.length)) ? (<Contacts title={title} contacts={contacts} />) : null}
        <FormSection formDescription={formDescription} />
      </Container>
    </div>
  );
}

export default ContactMain;
