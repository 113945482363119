import React from "react";
import { cn } from "../lib/helpers";
import { phoneUrl } from "../lib/helpers";

import * as styles from "../styles/components/contact-contacts.module.css";

const Contacts = (props) => {
  const {
    title,
    contacts
  } = props;

  return (
    <div className={styles.root}>
      <div className="grid">
        <div className="col-12 col-md-6">
          <div className="content-sm">
            {title && (<h1>{title}</h1>)}
          </div>
        </div>
        <div className="col-12 col-md-6">
          {(contacts && contacts.length) ? (
            <div className={cn("grid", styles.items)}>
              {contacts.map(item => {
                return (
                  <div className={cn("col-12 col-md-6", styles.item)} key={item._key}>
                    {item.heading && (<h2>{item.heading}</h2>)}
                    {item.phone && (<div className={styles.phone}><a href={"tel:"+phoneUrl(item.phone)} rel="noreferrer">{item.phone}</a></div>)}
                    {item.email && (<div className={styles.email}><a href={"mailto:"+item.email} rel="noreferrer">{item.email}</a></div>)}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Contacts;
