import React from "react";
import { Link, navigate } from "gatsby";
import { cn } from "../lib/helpers";
import Icon from "./icon";
import * as styles from "../styles/components/contact-form.module.css";

const Form = () => {

  const handleSubmit = (e) => {
    e.preventDefault();
    let myForm = document.getElementById("contact");
    let formData = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate("/thank-you"))
      .catch((error) => alert(error));
  };

  return (
    <div className={styles.root}>
      <form 
        method="post" 
        netlify-honeypot="bot-field" 
        data-netlify="true" 
        name="Contact Us" 
        id="contact" 
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="Contact Us" />
        <div className={styles.formFields}>
          <div className={styles.formField}>
            <div className="grid">
              <div className={cn("col-12 col-md-6", styles.fieldCol)}>
                <label className="screen-reader-text" htmlFor="fname">First Name*</label>
                <input type="text" name="fname" id="fname" placeholder="First Name*" required />
              </div>
              <div className={cn("col-12 col-md-6", styles.fieldCol)}>
                <label className="screen-reader-text" htmlFor="lname">Last Name*</label>
                <input type="text" name="lname" id="lname" placeholder="Last Name*" required />
              </div>
            </div>
          </div>
          <div className={styles.formField}>
            <label className="screen-reader-text" htmlFor="email">Email*</label>
            <input type="email" name="email" id="email" placeholder="Email*" required />
          </div>
          <div className={styles.formField}>
            <label className="screen-reader-text" htmlFor="message">Message*</label>
            <textarea name="message" id="message" placeholder="Message*" required />
          </div>
        </div>
        <div className={cn("entry-content", styles.legalCopy)}>
          <p>*All fields are required. Please review our <Link to="/privacy-policy">Privacy Policy</Link>.</p>
        </div>
        <button className={cn("btn", styles.btnSubmit)} type="submit">Submit<Icon symbol="arrow-right" /></button>
      </form>
    </div>
  );
}

export default Form;