import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import ContactMain from "../components/contact-main";

export const query = graphql`
  query ContactPageQuery {
    contact: sanityContact {
      title
      description
      contacts {
        _key
        heading
        phone
        email
      }
      formDescription {
        heading
        _rawContent
      }
    }
  }
`;

const ContactPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const contact = (data || {}).contact;

  return (
    <Layout>
      <Seo
        title={contact.title}
        description={contact.description}
      />

      {(contact.title || (contact.contacts && contact.contacts.length)) ? <ContactMain {...contact} /> : null}
    </Layout>
  );
};

export default ContactPage;
